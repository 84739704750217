<template>
  <div class="table-row" :class="{'table-row-top': top}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TableRow',
  data() {
    return {};
  },
  props: {
    // 是否显示顶部边框（非必传）
    top: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.table-row{
  position: relative;
  border-left: 1px solid #d3ddef;
  border-right: 1px solid #d3ddef;
  border-bottom: 1px solid #d3ddef;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}
.table-row-top{
  border-top: 1px solid #d3ddef;
}
</style>
