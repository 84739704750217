


















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class TableItem extends Vue {
  @Prop({ default: 1 }) private flex!: number;
  @Prop({ default: 100 }) private leftWidth!: number;
  @Prop() private title!: string;
  @Prop({ default: 'center' }) private titleSite!: string;
  @Prop({ default: false }) private noBor!: boolean;
  get itemStyle() {
    return `flex: ${this.flex};`;
  }
  get leftStyle() {
    return `width: ${this.$props.leftWidth}px;align-items: ${this.$props.titleSite};`;
  }
}
