import { post } from '../request';
import { Iresponse } from '../interface';

// 获取分支机构偶咨询列表
export const GetInstitutionArticleList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/cms/page', params });
};
// 修改分支机构偶咨询发布状态
export const InstitutionArticleState = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/cms/up/state', params });
};
// 删除分支机构偶咨询
export const InstitutionArticleDel = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/cms/del', params });
};
// 新增分支机构偶咨询
export const InstitutionArticleAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/cms/add', params });
};
// 获取分支机构偶详情
export const InstitutionArticleDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/cms/get', params });
};
// 修改分支机构偶咨询
export const InstitutionArticleUp = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/cms/up', params });
};
// 分支机构偶--资料上报
export const InstitutionReportAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/material/report/add', params });
};
// 分支机构偶--上传申请资料
export const InstitutionChangeSave = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/change/apply/save', params });
};
// 分支机构偶--获取申请状态
export const InstitutionChangeGet = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/change/apply/get', params });
};
// 分支机构偶--获取模板
export const InstitutionModuleGet = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/material/report/get', params });
};
// 分支机构偶--获取基本信息
export const InstitutionInfoGet = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/institution/get', params });
};
// 分支机构偶--修改基本信息
export const InstitutionInfoUp = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/institution/up', params });
};
// 分支机构 -- 会员管理 -- 单位会员管理 --列表
export const InstitutionUnitMember = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/page', params });
};
// 分支机构 -- 会员管理 -- 单位会员管理 --基础信息
export const InstitutionUnitMemberBase = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/basis', params });
};
// 分支机构 -- 会员管理 -- 单位会员管理 --单位信息详情
export const InstitutionUnitMemberDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/info', params });
};
// 分支机构 -- 会员管理 -- 单位会员管理 --单位代表详情
export const InstitutionUnitMemberInfo = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/reprst/info', params });
};
// 分支机构 -- 会员管理 -- 单位会员管理 --设置机构等级
export const InstitutionUnitMemberSetLevel = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/set/leve', params });
};
// 分支机构 -- 会员管理 -- 单位会员管理 --移出机构
export const InstitutionUnitMemberDel = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/remove', params });
};
// 分支机构 -- 会员管理 -- 个人会员管理 --列表
export const InstitutionPersonalMemberList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/person/page', params });
};
// 分支机构 -- 会员管理 -- 个人会员管理 --详情
export const InstitutionPersonalMemberDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/person/get', params });
};
// 分支机构 -- 会员管理 -- 个人会员管理 -- 删除
export const InstitutionPersonalMemberDel = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/person/remove', params });
};

//F分支机构---会费管理---列表
export const MemberList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/pay/voucher/page', params });
};



// 个人信息维护
export const personPprofile = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/person/info', params });
};

// 个人信息维护修改
export const personPup = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/person/up', params });
};

// 详情 更换等级
export const checkleve = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/check/change/leve', params });
};


