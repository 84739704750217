
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { InstitutionInfoGet, InstitutionInfoUp } from '@/http/api/InstitutionApi';
import TableRow from '@c/table/TableRow.vue';
import TableItem from '@c/table/TableItem.vue';

@Component({
  components: {
    TableRow,
    TableItem,
  },
})
export default class Information extends Vue {
  private pageLoading: boolean = false;
  private loading: boolean = false;
  private dialogShow: boolean = false;
  private userInfo: any = '';
  private created() {
    this.getInfo();
  }
  private getInfo(): void {
    this.pageLoading = true;
    InstitutionInfoGet({ module: 3, type: 1 }).then((res) => {
      this.pageLoading = false;
      if (res.code === '000') {
        this.userInfo = res.result;
      }
    });
  }
  private userSubmit() {
    this.loading = true;
    const pames = this.userInfo;
    pames.openDate = new Date(pames.openDate);
    pames.electionDate = new Date(pames.electionDate);
    pames.nextElectionDate = new Date(pames.nextElectionDate);
    InstitutionInfoUp(pames).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        this.$message.success('修改成功');
        this.getInfo();
        this.dialogShow = false;
      }
    });
  }
}
